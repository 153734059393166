var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from '@orbica/component-sdk';
import FormControlsStyles from '@orbica/component-sdk/build/css/formControls.scss';
import { skipToken } from '@reduxjs/toolkit/query';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetJobQuery } from '@Data/jobs/Api';
import { Analytics } from '@Services/analytics/Events';
import { JobDialogStage } from './Types';
import { DescriptionStage } from './components/DescriptionStage';
import { NotificationStage } from './components/NotificationStage';
import { ShareJobStage } from './components/ShareJobStage';
function getSubmitText(stage, isOwner, isSubmitting) {
    if (_isNil(stage))
        return null;
    switch (stage) {
        case JobDialogStage.Description:
            return `${isSubmitting ? 'Updating' : 'Update'} Job Description`;
        case JobDialogStage.Notification:
            if (!isOwner) {
                return `${isSubmitting ? 'Sharing' : 'Share'} Job`;
            }
            return `${isSubmitting ? 'Updating' : 'Update'} Notification Recipients`;
        default:
            throw new Error('Unhandled job dialog stage');
    }
}
function getIsDirty(stage, originalJob, clonedJob) {
    if (_isNil(originalJob) || _isNil(clonedJob))
        return false;
    function checkEquivalency(attributes) {
        let hasChanged = false;
        attributes.forEach((attribute) => {
            if (!_isEqual(originalJob[attribute], clonedJob[attribute])) {
                hasChanged = true;
                return;
            }
        });
        return hasChanged;
    }
    function checkEmailAddressEquivalency() {
        var _a;
        const originalAddresses = originalJob.job_users.map((u) => u.email_address);
        return (((_a = clonedJob.job_user_emails) === null || _a === void 0 ? void 0 : _a.every((address) => originalAddresses.includes(address))) &&
            originalAddresses.every((address) => clonedJob.job_user_emails.includes(address)));
    }
    switch (stage) {
        case JobDialogStage.Description:
            return (checkEquivalency(['name', 'description']) &&
                clonedJob.name !== '');
        case JobDialogStage.Notification:
            return ((clonedJob === null || clonedJob === void 0 ? void 0 : clonedJob.job_user_emails) &&
                (clonedJob === null || clonedJob === void 0 ? void 0 : clonedJob.job_user_emails.length) > 0 &&
                !checkEmailAddressEquivalency());
        default:
            return false;
    }
}
function getSharedEmails(job) {
    if (_isNil(job))
        return [];
    return job.job_users.map((jobUser) => jobUser.email_address);
}
export const EditJobDialog = (props) => {
    var _a, _b, _c;
    const [clonedJob, setClonedJob] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const posthog = usePostHog();
    const { user } = useAuth0();
    const { data: detailedJob, isFetching } = useGetJobQuery((_b = (_a = props.selectedJob) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : skipToken);
    useEffect(() => {
        if (props.isOpen &&
            !_isNil(detailedJob) &&
            props.selectedJob.id === detailedJob.id) {
            const cloned = _cloneDeep(detailedJob);
            cloned.job_user_emails = cloned.job_users.map((u) => u.email_address);
            setClonedJob(cloned);
        }
    }, [props.isOpen, props.selectedJob, detailedJob]);
    const handleClose = () => {
        props.onClose();
        setTimeout(() => setClonedJob(null), FormControlsStyles.transitionDuration);
    };
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsSubmitting(true);
        yield props.onEdit(clonedJob, props.stage);
        if (props.stage === JobDialogStage.Notification) {
            const originalAddresses = detailedJob.job_users.map((u) => u.email_address);
            const removedEmails = originalAddresses.filter((j) => !clonedJob.job_user_emails.includes(j));
            const newAddresses = clonedJob.job_user_emails.filter((j) => !originalAddresses.includes(j));
            posthog === null || posthog === void 0 ? void 0 : posthog.capture(Analytics.EditJob.RecipientsEdited, {
                removed: removedEmails,
                invited: newAddresses,
            });
        }
        setIsSubmitting(false);
        handleClose();
    });
    const updateJobAttribute = (attribute, value) => {
        const cloned = _cloneDeep(clonedJob);
        cloned[attribute] = value;
        setClonedJob(cloned);
    };
    const isUserOwner = ((_c = user === null || user === void 0 ? void 0 : user.user) === null || _c === void 0 ? void 0 : _c.id) === (detailedJob === null || detailedJob === void 0 ? void 0 : detailedJob.created_by_id);
    const title = isUserOwner ? 'Edit Job' : 'Share Job';
    const innerHtml = useMemo(() => {
        var _a, _b, _c, _d;
        if (isFetching || _isNil(clonedJob) || !props.isOpen) {
            return null;
        }
        switch (props.stage) {
            case JobDialogStage.Description:
                return (React.createElement(DescriptionStage, { name: clonedJob.name, onSetName: (name) => updateJobAttribute('name', name), description: clonedJob.description, onSetDescription: (description) => updateJobAttribute('description', description), cloud_coverage: clonedJob.cloud_coverage, onSetCloudCoverage: (cloud_coverage) => updateJobAttribute('cloud_coverage', cloud_coverage) }));
            case JobDialogStage.Notification:
                return ((_a = user === null || user === void 0 ? void 0 : user.user) === null || _a === void 0 ? void 0 : _a.id) === (detailedJob === null || detailedJob === void 0 ? void 0 : detailedJob.created_by_id) ? (React.createElement(NotificationStage, { notificationRecipients: clonedJob.job_user_emails, onUpdateRecipients: (recipients) => updateJobAttribute('job_user_emails', recipients), aoiCount: (_d = (_c = (_b = detailedJob === null || detailedJob === void 0 ? void 0 : detailedJob.full_geojson) === null || _b === void 0 ? void 0 : _b.features) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0 })) : (React.createElement(ShareJobStage, { notificationRecipients: clonedJob.job_user_emails, onUpdateRecipients: (recipients) => updateJobAttribute('job_user_emails', recipients), existingEmails: getSharedEmails(detailedJob) }));
            default:
                throw new Error('Job dialog stage not handled');
        }
    }, [props.stage, clonedJob, isFetching]);
    const canSubmit = getIsDirty(props.stage, detailedJob, clonedJob);
    return (React.createElement(Dialog, { open: props.isOpen, onClose: handleClose, disableBackdropClick: true, title: title, FooterProps: {
            onSubmit: handleSave,
            isSubmitting: isSubmitting,
            submitText: getSubmitText(props.stage, isUserOwner, false),
            submittingText: getSubmitText(props.stage, isUserOwner, true),
            isSubmitDisabled: !canSubmit,
        } }, innerHtml));
};
