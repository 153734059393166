import { PrimaryButton } from '@orbica/component-sdk';
import { skipToken } from '@reduxjs/toolkit/query';
import { ReactComponent as ExportIcon } from '@Icons/md/export.svg';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BackButton } from '@Components/Buttons/BackButton';
import { ViewResultsButton } from '@Components/Buttons/ViewResultsButton';
import { TitleWithActionContainer } from '@Components/Containers';
import { ResourceNotFound } from '@Components/ResourceNotFound/ResourceNotFound';
import { useGetJobQuery } from '@Data/jobs/Api';
import { Navigation } from '@Services/navigation/Navigation';
import { AoisTable } from './components/AoisTable/AoisTable';
import { JobDescription } from './components/JobDescription/JobDescription';
import { JobReportDialog } from './Dialogs/JobReportDialog';
export const AoisScheduleView = () => {
    const defaultOptions = {
        reportStartDate: null,
        reportEndDate: null,
    };
    const { jobId, aoiId } = useParams();
    const [isModalOpen, setisModalOpen] = useState(false);
    const [reportStartDate, setreportStartDate] = useState(defaultOptions.reportStartDate);
    const [reportEndDate, setreportEndDate] = useState(defaultOptions.reportEndDate);
    const { data: selectedJob, isFetching } = useGetJobQuery(jobId !== null && jobId !== void 0 ? jobId : skipToken);
    if (!isFetching && !selectedJob) {
        return React.createElement(ResourceNotFound, { resourceName: "Job" });
    }
    const handleOpenModal = () => {
        setisModalOpen(true);
    };
    const handleSetBaselineEndDate = (reportEndDate) => {
        setreportEndDate(reportEndDate);
    };
    const actions = (React.createElement(React.Fragment, null,
        React.createElement(ViewResultsButton, { href: Navigation.explore.results.getUrl({
                jobId: jobId,
                load: true,
            }) }),
        React.createElement(PrimaryButton, { onClick: handleOpenModal, size: "medium", StartIconProps: {
                icon: React.createElement(ExportIcon, null),
            }, disabled: !selectedJob || selectedJob.last_run_date === null }, "Reports"),
        React.createElement(BackButton, { href: "/schedule" })));
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleWithActionContainer, { isLoading: isFetching, title: selectedJob === null || selectedJob === void 0 ? void 0 : selectedJob.name, actions: actions }),
        React.createElement(JobDescription, { selectedJob: selectedJob, isLoading: isFetching }),
        isModalOpen &&
            React.createElement(JobReportDialog, { jobId: jobId, aoiId: aoiId, isOpen: isModalOpen, onClose: () => setisModalOpen(false), onSetStartDate: (job_min_date) => setreportStartDate(job_min_date), onSetEndDate: handleSetBaselineEndDate }),
        React.createElement(AoisTable, null)));
};
