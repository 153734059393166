import { skipToken } from '@reduxjs/toolkit/query';
import { ReactComponent as ExportIcon } from '@Icons/md/export.svg';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PrimaryButton } from '@orbica/component-sdk';
import { BackButton } from '@Components/Buttons/BackButton';
import { ViewResultsButton } from '@Components/Buttons/ViewResultsButton';
import { TitleWithActionContainer } from '@Components/Containers';
import { ResourceNotFound } from '@Components/ResourceNotFound/ResourceNotFound';
import { useGetAoiQuery } from '@Data/aois/Api';
import { useGetAoiRunsQuery } from '@Data/aoiRuns/Api';
import { Navigation } from '@Services/navigation/Navigation';
import { AoiDescription } from './components/AoiDescription/AoiDescription';
import { AoiRunsTable } from './components/AoiRunsTable/AoiRunsTable';
import { AoiReportDialog } from './Dialogs/AoiReportDialog';
const defaultOptions = {
    reportStartDate: null,
    reportEndDate: null,
};
export const AoiRunsScheduleView = () => {
    const [isModalOpen, setisModalOpen] = useState(false);
    const [reportStartDate, setreportStartDate] = useState(defaultOptions.reportStartDate);
    const [reportEndDate, setreportEndDate] = useState(defaultOptions.reportEndDate);
    const [filteredRuns, setFilteredRuns] = useState([]);
    const { jobId, aoiId } = useParams();
    const { data: selectedAoi, isFetching: isFetchingAoi } = useGetAoiQuery(aoiId !== null && aoiId !== void 0 ? aoiId : skipToken);
    const { data: aoiRuns, isFetching: isFetchingaoiRuns } = useGetAoiRunsQuery(aoiId
        ? {
            aoi_id: aoiId,
        }
        : skipToken);
    if (!isFetchingAoi && !selectedAoi) {
        return React.createElement(ResourceNotFound, { resourceName: "Area of Interest" });
    }
    useEffect(() => {
        if (aoiRuns) {
            const filteredRuns = aoiRuns.aoiRuns.filter(run => run.status === 'COMPLETED');
            setFilteredRuns(filteredRuns);
        }
    }, [aoiRuns]);
    const handleOpenModal = () => {
        setisModalOpen(true);
    };
    const handleSetBaselineEndDate = (reportEndDate) => {
        setreportEndDate(reportEndDate);
    };
    const actions = (React.createElement(React.Fragment, null,
        React.createElement(ViewResultsButton, { href: Navigation.explore.results.getUrl({
                jobId: jobId,
                aoiIds: aoiId,
                load: true,
            }) }),
        React.createElement(PrimaryButton, { onClick: handleOpenModal, size: "medium", StartIconProps: {
                icon: React.createElement(ExportIcon, null),
            }, disabled: Object.entries(filteredRuns).length === 0 || selectedAoi === undefined }, "Request an AOI Report"),
        React.createElement(BackButton, { href: Navigation.schedule.aois.getUrl(jobId) })));
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleWithActionContainer, { isLoading: isFetchingAoi, title: selectedAoi === null || selectedAoi === void 0 ? void 0 : selectedAoi.name, actions: actions }),
        React.createElement(AoiDescription, { selectedAoi: selectedAoi, isLoading: isFetchingAoi }),
        isModalOpen &&
            React.createElement(AoiReportDialog, { jobId: jobId, aoiId: aoiId, isOpen: isModalOpen, onClose: () => setisModalOpen(false), onSetStartDate: (job_min_date) => setreportStartDate(job_min_date), onSetEndDate: handleSetBaselineEndDate }),
        React.createElement(AoiRunsTable, null)));
};
