export var JobDialogStage;
(function (JobDialogStage) {
    JobDialogStage[JobDialogStage["Description"] = 0] = "Description";
    JobDialogStage[JobDialogStage["Indices"] = 1] = "Indices";
    JobDialogStage[JobDialogStage["AreasOfInterest"] = 2] = "AreasOfInterest";
    JobDialogStage[JobDialogStage["Schedule"] = 3] = "Schedule";
    JobDialogStage[JobDialogStage["Report"] = 4] = "Report";
    JobDialogStage[JobDialogStage["Notification"] = 5] = "Notification";
})(JobDialogStage || (JobDialogStage = {}));
export function getJobDialogStageName(stage) {
    switch (stage) {
        case JobDialogStage.Description:
            return 'Description';
        case JobDialogStage.AreasOfInterest:
            return 'AreasOfInterest';
        case JobDialogStage.Indices:
            return 'Indices';
        case JobDialogStage.Schedule:
            return 'Schedule';
        case JobDialogStage.Report:
            return 'Report';
        case JobDialogStage.Notification:
            return 'Notification';
        default:
            return 'Unhandled stage';
    }
}
