import { DatePicker, Label, Slider } from '@orbica/component-sdk';
import GridStyles from '@orbica/component-sdk/build/css/grid.scss';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import cx from 'classnames';
import _debounce from 'lodash/debounce';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { TitleAndDescription } from './TitleAndDescription';
import { SENTINEL_2_MIN_DATE } from '@Data/imagery/helpers';
const getCadenceEndDate = (startDate, cadences, cadencePeriod) => {
    const durationInDays = cadencePeriod * cadences;
    return startDate.plus({ days: durationInDays });
};
export const ScheduleStage = (props) => {
    const [cadences, setCadences] = useState(10);
    const [endCadenceSliderKey, setEndCadenceSliderKey] = useState(Math.random().toString(16));
    const [baselineEndDate, setBaselineEndDate] = useState(null);
    const [baselineStartDate, setBaselineStartDate] = useState(null);
    useEffect(() => {
        if (_isNil(props.durationEndDate) &&
            !_isNil(props.durationStartDate) &&
            !_isNil(props.scheduleCadence)) {
            const calculatedEndDate = getCadenceEndDate(props.durationStartDate, cadences, props.scheduleCadence);
            props.onSetDurationEndDate(calculatedEndDate);
            props.onSetBaselineEndDate(props.durationStartDate);
            props.onSetBaselineStartDate(props.durationStartDate.minus({ months: 2 }));
        }
    }, [
        cadences,
        props.durationEndDate,
        props.durationStartDate,
        props.scheduleCadence,
    ]);
    const handleChangeScheduleCadence = _debounce((newCadences) => {
        props.onSetScheduleCadence(newCadences);
        if (!_isNil(props.durationStartDate) && !_isNil(cadences)) {
            const newEndDate = getCadenceEndDate(props.durationStartDate, cadences, newCadences);
            props.onSetDurationEndDate(newEndDate);
        }
    }, 300);
    const handleChangeManualEndDate = (newDate) => {
        const differenceInDays = newDate
            .diff(props.durationStartDate)
            .as('days');
        const calculatedCadences = differenceInDays / props.scheduleCadence;
        setCadences(Math.ceil(calculatedCadences));
        setEndCadenceSliderKey(Math.random().toString(16));
        props.onSetDurationEndDate(newDate);
    };
    const handleChangeEndCandences = _debounce((newCadences) => {
        const newEndDate = getCadenceEndDate(props.durationStartDate, newCadences, props.scheduleCadence);
        props.onSetDurationEndDate(newEndDate);
    }, 300);
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleAndDescription, { title: `Set the timeframe and frequency for monitoring your area${props.aoiCount > 1 ? 's' : ''}.` }),
        React.createElement("div", { className: cx(GridContentStyles.max, GridStyles.dialogGrid) },
            React.createElement(DatePicker, { size: "large", value: props.durationStartDate, onChange: (date) => props.onSetDurationStartDate(date), minDate: SENTINEL_2_MIN_DATE !== null && SENTINEL_2_MIN_DATE !== void 0 ? SENTINEL_2_MIN_DATE : null, containerClassName: GridContentStyles.mediumSmall, label: "When do you want to start monitoring?" })),
        React.createElement("div", { className: cx(GridContentStyles.max, GridStyles.dialogGrid) },
            React.createElement(Slider, { defaultValue: props.scheduleCadence, valueLabelFormat: (v) => `Every ${v} day${v > 1 ? 's' : ''}`, onChange: (e) => handleChangeScheduleCadence(e.target.value), min: 7, max: 365, containerClassName: GridContentStyles.mediumSmall, label: "How often do you want to monitor?" })),
        !_isNil(props.durationStartDate) && (React.createElement("div", { className: GridContentStyles.max },
            React.createElement(Label, { label: "When do you want to end monitoring?" }),
            React.createElement("div", { className: GridStyles.dialogGrid },
                React.createElement(DatePicker, { value: props.durationEndDate, containerClassName: GridContentStyles.mediumSmall, onChange: (date) => handleChangeManualEndDate(date), minDate: props.durationStartDate.plus({
                        days: props.scheduleCadence,
                    }) }),
                React.createElement(Slider, { key: endCadenceSliderKey, min: 1, defaultValue: cadences, valueLabelFormat: (v) => `${v} cycle${v > 1 ? 's' : ''}`, containerClassName: GridContentStyles.mediumSmall, onChange: (e) => handleChangeEndCandences(e.target.value) }))))));
};
