var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog, Stepper, isEmailAddressValid } from '@orbica/component-sdk';
import FormControlsStyles from '@orbica/component-sdk/build/css/formControls.scss';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import _isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import posthog from 'posthog-js';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import { ConfirmCancelProcessDialog } from '@Components/Dialogs/ConfirmCloseDialog';
import { useGetImageryPlatformsQuery } from '@Data/imagery/Api';
import { useCreateJobMutation } from '@Data/jobs/Api';
import { Analytics } from '@Services/analytics/Events';
import { FeatureFlags } from '@Services/featureFlags/FeatureFlags';
import { createToast } from '@Services/notifications/NotificationService';
import { JobDialogStage, getJobDialogStageName } from './Types';
import { AreasOfInterestStage } from './components/AreasOfInterestStage';
import { DescriptionStage } from './components/DescriptionStage';
import { IndicesStage } from './components/IndicesStage';
import { NotificationStage } from './components/NotificationStage';
import { ScheduleStage } from './components/ScheduleStage';
import { ReportStage } from './components/ReportStage';
let hasLoaded = false;
function areAoisValid(aois, useCloudCover) {
    return !aois.some((aoi) => {
        var _a;
        const isNameInvalid = _isNil(aoi.properties.name) || aoi.properties.name === '';
        const hasValidBaselineImages = !useCloudCover || ((_a = aoi.properties.baselineCloudCover) === null || _a === void 0 ? void 0 : _a.isValid);
        return isNameInvalid || !hasValidBaselineImages;
    });
}
const defaultOptions = {
    initialPage: JobDialogStage.Description,
    name: '',
    description: '',
    imageryPlatform: null,
    theme: null,
    imageryIndices: [],
    cloudCover: 20,
    areasOfInterest: [],
    scheduleCadence: 7,
    reportCadence: 30,
    reportCadenceLabel: 'Monthly',
    sensitivity: 5,
    sensitivity_label: 'High',
    baselineStartDate: null,
    baselineEndDate: null,
    durationStartDate: null,
    durationEndDate: null,
    notificationRecipients: [],
    changeType: '',
    selectedIndex: ''
};
export const CreateJobDialog = (props) => {
    const isCloudCoverFlagEnabled = useFeatureFlagEnabled(FeatureFlags.checkAoiCloudCover);
    const [createJob, { isLoading: isCreatingJob }] = useCreateJobMutation();
    const { data: imageryProviders, isFetching: areProvidersFetching } = useGetImageryPlatformsQuery();
    const { user, getIdTokenClaims } = useAuth0();
    const [isConfirmCancelDialogOpen, setIsConfirmCancelDialogOpen] = useState(false);
    const [jobStage, setJobStage] = useState(defaultOptions.initialPage);
    const [name, setName] = useState(defaultOptions.name);
    const [description, setDescription] = useState(defaultOptions.description);
    const [cloud_coverage, setCloudCoverage] = useState(defaultOptions.cloudCover);
    const [imageryPlatform, setImageryPlatform] = useState(defaultOptions.imageryPlatform);
    const [theme, setTheme] = useState(null);
    const [domain, setDomain] = useState(null);
    const [imageryIndices, setImageryIndices] = useState(defaultOptions.imageryIndices);
    const [areasOfInterest, setAreasOfInterest] = useState(defaultOptions.areasOfInterest);
    const [cloudCover, setCloudCover] = useState(defaultOptions.cloudCover);
    const [baselineStartDate, setBaselineStartDate] = useState(defaultOptions.baselineStartDate);
    const [baselineEndDate, setBaselineEndDate] = useState(defaultOptions.baselineEndDate);
    const [scheduleCadence, setScheduleCadence] = useState(defaultOptions.scheduleCadence);
    const [reportCadence, setReportCadence] = useState(defaultOptions.reportCadence);
    const [reportCadenceLabel, setReportCadenceLabel] = useState(defaultOptions.reportCadenceLabel);
    const [sensitivity, setSensitivity] = useState(defaultOptions.sensitivity);
    const [sensitivityLabel, setSensitivityLabel] = useState(defaultOptions.sensitivity_label);
    const [changeType, setChangeType] = useState(defaultOptions.changeType);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [durationStartDate, setDurationStartDate] = useState(defaultOptions.durationStartDate);
    const [durationEndDate, setDurationEndDate] = useState(defaultOptions.durationEndDate);
    const [notificationRecipients, setNotificationRecipients] = useState(defaultOptions.notificationRecipients);
    useEffect(() => {
        if (!_isNil(props.areaOfInterestFeatures)) {
            setAreasOfInterest([
                ...props.areaOfInterestFeatures,
            ]);
        }
    }, [props.areaOfInterestFeatures]);
    useEffect(() => {
        if (!_isNil(imageryProviders) &&
            imageryProviders.length > 0 &&
            _isNil(imageryPlatform)) {
            setImageryPlatform(imageryProviders[0]);
        }
    }, [imageryProviders, imageryPlatform]);
    const cleanup = () => {
        setJobStage(defaultOptions.initialPage);
        setName(defaultOptions.name);
        setDescription(defaultOptions.description);
        setImageryIndices(defaultOptions.imageryIndices);
        setCloudCover(defaultOptions.cloudCover);
        setTheme(defaultOptions.theme);
        setAreasOfInterest(defaultOptions.areasOfInterest);
        setNotificationRecipients(defaultOptions.notificationRecipients);
        setBaselineStartDate(defaultOptions.baselineStartDate);
        setBaselineEndDate(defaultOptions.baselineEndDate);
        setDurationStartDate(defaultOptions.durationStartDate);
        setDurationEndDate(defaultOptions.durationEndDate);
        setScheduleCadence(defaultOptions.scheduleCadence);
        setReportCadence(defaultOptions.reportCadence);
        setReportCadenceLabel(defaultOptions.reportCadenceLabel);
        setSensitivity(defaultOptions.sensitivity);
        setSensitivityLabel(defaultOptions.sensitivity_label);
        setChangeType(defaultOptions.changeType);
        hasLoaded = false;
    };
    useEffect(() => {
        if (props.isOpen && !hasLoaded) {
            hasLoaded = true;
            posthog === null || posthog === void 0 ? void 0 : posthog.capture(Analytics.CreateJob.DialogOpened);
            setNotificationRecipients((currentRecipients) => [
                ...currentRecipients,
                user.email,
            ]);
            if (user.email === 'emadmin@orbica.com') {
                createToast("Don't create jobs as EM Admin!", 'error');
                handleClose();
            }
        }
    }, [props.isOpen, hasLoaded, posthog]);
    const handleClose = (didCreateJob = false) => {
        props.onClose();
        if (!didCreateJob) {
            posthog === null || posthog === void 0 ? void 0 : posthog.capture(Analytics.CreateJob.DialogClosed, {
                page: getJobDialogStageName(jobStage),
            });
        }
        setTimeout(cleanup, FormControlsStyles.transitionDuration);
    };
    const handleCreateJob = () => __awaiter(void 0, void 0, void 0, function* () {
        const mappedAreasOfInterest = areasOfInterest.map((aoi) => {
            var _a, _b, _c;
            return Object.assign(Object.assign({}, aoi), { properties: {
                    name: aoi.properties.name,
                    valid_time_list: (_b = (_a = aoi.properties.baselineCloudCover) === null || _a === void 0 ? void 0 : _a.valid_time_list) !== null && _b !== void 0 ? _b : null,
                    area: (_c = aoi.properties.area) !== null && _c !== void 0 ? _c : null,
                } });
        });
        const spectralIndices = imageryIndices.map((i) => {
            return {
                index_key: i.asi_short_name,
                domain: i.domain,
            };
        });
        const baselineObj = {
            data: {
                start_date: baselineStartDate.toISO(),
                end_date: baselineEndDate.toISO(),
            },
        };
        const featureCollection = {
            type: 'FeatureCollection',
            features: mappedAreasOfInterest,
        };
        const idTokenClaims = yield getIdTokenClaims();
        yield createJob({
            name: name,
            description: description,
            start_date: durationStartDate.toISO(),
            end_date: durationEndDate.toISO(),
            feature_collection: featureCollection,
            cloud_coverage: cloudCover,
            platform_id: imageryPlatform.id,
            spectral_indices: spectralIndices,
            baseline: baselineObj,
            cadence: scheduleCadence,
            report_cadence_label: reportCadenceLabel,
            sensitivity: sensitivity / 10,
            created_by_id: idTokenClaims.user.id,
            email_addresses: notificationRecipients,
            theme_id: theme.id,
            is_active: true,
            organization_id: idTokenClaims.organization.id,
            sensitivity_label: sensitivityLabel,
            changeType: changeType,
            selected_report: selectedIndex
        });
        posthog === null || posthog === void 0 ? void 0 : posthog.capture(Analytics.CreateJob.JobCreated, {
            name: name,
            description: description,
            theme: theme.name,
            indices: spectralIndices.map((i) => i.index_key),
            platform: imageryPlatform.name,
            cloud_coverage: cloudCover,
            cadence: scheduleCadence,
            report_cadence: reportCadence,
            sensitivity: sensitivity,
            change_type: changeType,
            aoi_count: areasOfInterest.length,
            start_date: durationStartDate.toLocaleString(DateTime.DATETIME_MED),
            end_date: durationEndDate.toLocaleString(DateTime.DATETIME_MED),
            baseline: baselineStartDate === baselineEndDate
                ? 'latest'
                : {
                    start: baselineStartDate.toLocaleString(DateTime.DATE_MED),
                    end: baselineStartDate.toLocaleString(DateTime.DATE_MED),
                },
            users: notificationRecipients,
        });
        handleClose(true);
    });
    const handleUpdateAreaOfInterest = (updatedFeature) => {
        setAreasOfInterest((areasOfInterest) => areasOfInterest.map((aoi) => aoi.id === updatedFeature.id ? updatedFeature : aoi));
    };
    const handleSetBaselineEndDate = (baselineEndDate) => {
        setBaselineEndDate(baselineEndDate);
        setDurationStartDate(baselineEndDate);
    };
    const getBodyContent = () => {
        switch (jobStage) {
            case JobDialogStage.Description:
                return (React.createElement(DescriptionStage, { name: name, onSetName: (name) => setName(name), description: description, onSetDescription: (description) => setDescription(description), cloud_coverage: cloudCover, onSetCloudCoverage: (cloudCover) => setCloudCover(cloudCover) }));
            case JobDialogStage.Indices:
                return (React.createElement(IndicesStage, { imageryIndices: imageryIndices, onUpdateImageryIndices: (indices) => setImageryIndices(indices), theme: theme, onSetTheme: (theme) => setTheme(theme), domain: domain, onSetDomain: (domain) => setDomain(domain) }));
            case JobDialogStage.AreasOfInterest:
                return (React.createElement(AreasOfInterestStage, { areasOfInterest: areasOfInterest, onUpdateAreasOfInterest: (areas) => {
                        setAreasOfInterest(areas);
                    }, baselineStartDate: baselineStartDate, baselineEndDate: baselineEndDate, onUpdateFeature: handleUpdateAreaOfInterest }));
            case JobDialogStage.Schedule:
                return (React.createElement(ScheduleStage, { scheduleCadence: scheduleCadence, onSetScheduleCadence: (scheduleOption) => setScheduleCadence(scheduleOption), durationStartDate: durationStartDate, durationEndDate: durationEndDate, onSetDurationStartDate: (date) => setDurationStartDate(date), onSetDurationEndDate: (date) => setDurationEndDate(date), aoiCount: areasOfInterest.length, baselineEndDate: baselineEndDate, baselineStartDate: baselineStartDate, onSetBaselineEndDate: (date) => setBaselineEndDate(date), onSetBaselineStartDate: (date) => setBaselineStartDate(date) }));
            case JobDialogStage.Report:
                return (React.createElement(ReportStage, { reportCadenceLabel: reportCadenceLabel, onSetReportCadence: (ReportOption) => setReportCadence(ReportOption), onSetReportCadenceLabel: (ReportOption) => setReportCadenceLabel(ReportOption), sensitivityValue: sensitivity, sensitivityLabel: sensitivityLabel, onSetSensitivity: (sensitivity) => setSensitivity(sensitivity), onSetSensitivityLabel: (sensitivityLabel) => setSensitivityLabel(sensitivityLabel), changeType: changeType, onSetChangeType: (changeType) => setChangeType(changeType), onSetSelectedIndex: (selectedIndex) => setSelectedIndex(selectedIndex), theme: theme }));
            case JobDialogStage.Notification:
                return (React.createElement(NotificationStage, { notificationRecipients: notificationRecipients, onUpdateRecipients: (recipients) => setNotificationRecipients(recipients), aoiCount: areasOfInterest.length }));
        }
    };
    const areAllNotificationRecipientsValid = notificationRecipients.every((recipient) => isEmailAddressValid(recipient));
    const next = () => {
        setJobStage(jobStage + 1);
    };
    const prev = () => {
        setJobStage(jobStage - 1);
    };
    const getFooterProps = () => {
        switch (jobStage) {
            case JobDialogStage.Description:
                return {
                    cancelText: 'Cancel',
                    submitText: 'Next',
                    isSubmitDisabled: name === '',
                    onSubmit: next,
                };
            case JobDialogStage.AreasOfInterest:
                return {
                    cancelText: 'Back',
                    submitText: 'Next',
                    onCancel: prev,
                    onSubmit: next,
                    isSubmitDisabled: areasOfInterest.length === 0 ||
                        !areAoisValid(areasOfInterest, isCloudCoverFlagEnabled),
                };
            case JobDialogStage.Indices:
                return {
                    cancelText: 'Back',
                    submitText: 'Next',
                    onCancel: prev,
                    onSubmit: next,
                    isSubmitDisabled: imageryIndices.length === 0 || _isNil(theme),
                };
            case JobDialogStage.Schedule:
                return {
                    cancelText: 'Back',
                    submitText: 'Next',
                    onCancel: prev,
                    onSubmit: next,
                    isSubmitDisabled: _isNil(scheduleCadence) ||
                        _isNil(durationStartDate) ||
                        _isNil(durationEndDate) ||
                        durationEndDate <= durationStartDate,
                };
            case JobDialogStage.Report:
                return {
                    cancelText: 'Back',
                    submitText: 'Next',
                    onCancel: prev,
                    onSubmit: next,
                    isSubmitDisabled: _isNil(changeType) ||
                        _isNil(reportCadence) ||
                        _isNil(sensitivity),
                };
            case JobDialogStage.Notification:
                return {
                    cancelText: 'Back',
                    submitText: 'Create Job',
                    submittingText: 'Creating Job',
                    onCancel: prev,
                    onSubmit: handleCreateJob,
                    isSubmitDisabled: notificationRecipients.length === 0 ||
                        !areAllNotificationRecipientsValid,
                    isSubmitting: isCreatingJob,
                };
        }
    };
    return (React.createElement(Dialog, { title: 'Create a new Monitoring Job', onClose: () => setIsConfirmCancelDialogOpen(true), open: props.isOpen, FooterProps: getFooterProps(), disableBackdropClick: true, grow: jobStage === JobDialogStage.AreasOfInterest, BodyProps: {
            style: {
                width: jobStage === JobDialogStage.AreasOfInterest
                    ? '80vw'
                    : 'auto',
            },
        } },
        React.createElement(React.Fragment, null,
            React.createElement(Stepper, { steps: 7, activeStep: jobStage, className: GridContentStyles.max }),
            getBodyContent(),
            React.createElement(ConfirmCancelProcessDialog, { processName: "creating job", isOpen: isConfirmCancelDialogOpen, onClose: () => setIsConfirmCancelDialogOpen(false), onCancelProcess: handleClose }))));
};
