var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog, Label, Select, SelectOption, PrimaryButton } from '@orbica/component-sdk';
import { DateRangePicker } from '@orbica/component-sdk';
import { DateTime } from 'luxon';
import { ConfirmCancelProcessDialog } from '@Components/Dialogs/ConfirmCloseDialog';
import { useGetJobQuery } from '@Data/jobs/Api';
import { aoisReportApi } from '@Data/aois/Api';
import { useGetAoisSimpleQuery } from '@Data/aois/Api';
import { useGetAoiQuery } from '@Data/aois/Api';
import { SecondaryInfoContainer } from '@Components/Containers';
import styled from 'styled-components';
import cx from 'classnames';
import GridStyles from '@orbica/component-sdk/build/css/grid.scss';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import { skipToken } from '@reduxjs/toolkit/query';
import { downloadzipFileFromS3 } from '@Data/runResults/Api';
import { downloadFileToLocal, extractFilenameFromPath } from '@Services/helpers/Files';
const StyledLabelContainer = styled('div') `
    display: grid;
    column-gap: ${GridStyles.columnGap};
    row-gap: ${GridStyles.rowGap};
    grid-template-columns: 1fr;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;
const StyledIndicesContainer = styled(StyledLabelContainer) `
    margin-top: 24px;
`;
const StyledIndicesDescription = styled('div') `
    display: flex;
    flex-direction: column;

    .learnMore {
        display: flex;
        align-items: baseline;
        a {
            margin-right: 3px;
        }
    }
`;
const StyledSelectedIndicesContainer = styled('div') `
    display: flex;
    gap: 4px;
    margin-top: 2px;
`;
const StyledValidationDialogContent = styled('div') `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-size: 16px;
    color: #333;
    width: 100%;

    .message {
        margin-bottom: 16px;
        text-align: center;
    }
`;
const StyledButtonContainer = styled('div') `
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;
const SliderContainer = styled('div') `
    display: flex;
    align-items: center;
    gap: 16px;
`;
const SliderWrapper = styled('div') `
    flex: 1;
`;
const SensitivityDescription = styled('div') `
    flex: 0.5;
    text-align: left;
`;
export const JobReportDialog = (props) => {
    const { aoiId, jobId, onSetStartDate, onSetEndDate } = props;
    const { user } = useAuth0();
    const { data: jobDetails, isLoading, error } = useGetJobQuery(jobId);
    const { data: selectedAoi } = useGetAoiQuery(aoiId !== null && aoiId !== void 0 ? aoiId : skipToken);
    const { data, isFetching } = useGetAoisSimpleQuery({
        jobId,
    });
    const { aois, count } = data !== null && data !== void 0 ? data : { aois: [], count: 0 };
    const aoiIds = aois.map(aoi => aoi.id);
    const [filteredRuns, setFilteredRuns] = useState([]);
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [isConfirmCancelDialogOpen, setIsConfirmCancelDialogOpen] = useState(false);
    const [spectralIndices, setSpectralIndices] = useState([]);
    const [selectedIndices, setSelectedIndices] = useState([]);
    const [methodType, setMethodType] = useState();
    const [report, setReport] = useState();
    const [reportPath, setReportPath] = useState();
    const [methodParams, setMethodParams] = useState();
    const [changeType, setChangeType] = useState();
    const [isValidationDialogOpen, setIsValidationDialogOpen] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [sensitivity, setSensitivity] = useState(props.sensitivityValue || 5);
    const [sensitivityLabel, setSensitivityLabel] = useState(props.sensitivityLabel);
    const MethodOptionMap = jobDetails.theme.theme_spectral_indices.reduce((acc, index) => {
        if (index.report_name)
            acc[index.report_name] = index.report_name;
        return acc;
    }, {});
    const MethodParamsMap = jobDetails.theme.theme_spectral_indices.reduce((acc, index) => {
        if (index.report_name)
            acc[index.report_name] = index;
        return acc;
    }, {});
    const ReportOptionPathMap = jobDetails.job_reports.reduce((acc, report) => {
        if (report.report_name)
            acc[report.report_name] = report.report_path;
        return acc;
    }, {});
    const ReportOptionMap = jobDetails.job_reports.reduce((acc, report) => {
        if (report.report_name)
            acc[report.report_name] = report.report_name;
        return acc;
    }, {});
    const selectedMethodDisplayName = methodType ? MethodOptionMap[methodType] : 'Select a report type';
    const setlectedMethodParams = methodType ? MethodParamsMap[methodType] : '';
    const selectedReport = report ? ReportOptionMap[report] : 'Select a report';
    const selectedReportPath = report ? ReportOptionPathMap[report] : '';
    useEffect(() => {
        if (!jobDetails)
            return;
        const jobStartDate = DateTime.fromISO(jobDetails.start_date);
        const jobEndDate = DateTime.fromISO(jobDetails.end_date);
        if (!customStartDate && !customEndDate) {
            setCustomStartDate(jobStartDate);
            setCustomEndDate(jobEndDate);
            onSetStartDate(jobStartDate);
            onSetEndDate(jobEndDate);
        }
        const indices = jobDetails.theme.name === "custom"
            ? jobDetails.job_spectral_indices.map(index => ({
                id: index.id,
                theme_index_key: index.index_key,
                index_key: index.index_key
            }))
            : jobDetails.theme.theme_spectral_indices.map(index => ({
                id: index.spectral_index.id,
                theme_index_key: index.name,
                index_key: index.spectral_index.asi_short_name
            }));
        setSpectralIndices(indices);
        if (selectedReportPath) {
            onDownloadReportClick(selectedReportPath);
        }
    }, [jobDetails, customStartDate, customEndDate, onSetEndDate, onSetStartDate, aois, selectedReportPath]);
    const handleValidationDialogClose = () => {
        setIsValidationDialogOpen(false);
        props.onClose();
    };
    const handleSelectMethod = (value) => {
        setMethodType(value);
        setMethodParams(value);
    };
    const handleSelectReportMethod = (value) => {
        setReport(value);
        setReportPath(value);
    };
    const handleSetCustomDate = (value, period) => {
        if (period === 'start') {
            if (value <= customEndDate || !customEndDate) {
                setCustomStartDate(value);
                onSetStartDate(value);
            }
        }
        else if (period === 'end') {
            if (value >= customStartDate || !customStartDate) {
                setCustomEndDate(value);
                onSetEndDate(value);
            }
        }
    };
    const dispatch = useDispatch();
    const getFooterProps = () => {
        return {
            cancelText: 'Cancel Report',
            submitText: 'Create Job Report',
            submittingText: 'Creating Job Report',
            isSubmitDisabled: methodType === null,
            onCancel: () => setIsConfirmCancelDialogOpen(true),
            onSubmit: () => __awaiter(void 0, void 0, void 0, function* () {
                const selectedIndicesForSubmit = selectedIndices;
                const startDateForSubmit = customStartDate === null || customStartDate === void 0 ? void 0 : customStartDate.toISO();
                const endDateForSubmit = customEndDate === null || customEndDate === void 0 ? void 0 : customEndDate.toISO();
                try {
                    setValidationMessage("Your report is currently being processed. You will receive an email when it is ready.");
                    setIsValidationDialogOpen(true);
                    dispatch(aoisReportApi.endpoints.getAoiReport.initiate({
                        aoi_ids: aoiIds,
                        start_date: startDateForSubmit,
                        end_date: endDateForSubmit,
                        selected_indices: [setlectedMethodParams["spectral_index"]["asi_short_name"]],
                        user_email: user.email,
                        theme: jobDetails.theme.name,
                        chng_type: setlectedMethodParams["chng_type"],
                        min_area: setlectedMethodParams["min_area"],
                        percentile: setlectedMethodParams["percentile"],
                        merging_px_grp: setlectedMethodParams["merging_px_grp"],
                        shape_simplify: setlectedMethodParams["shape_simplify"],
                        erode_kernel_size: setlectedMethodParams["erode_kernel_size"],
                        delete_last_day: setlectedMethodParams["delete_last_day"],
                        extraction_type: setlectedMethodParams["extraction_type"],
                        change_algorithm: setlectedMethodParams["change_algorithm"],
                        base_threshold: setlectedMethodParams["base_threshold"],
                        event_type: setlectedMethodParams["event_type"],
                        index_threshold_down: setlectedMethodParams["index_threshold_down"],
                        index_threshold_up: setlectedMethodParams["index_threshold_up"],
                        min_area_in_sqm: setlectedMethodParams["min_area_in_sqm"],
                        chart_overlay: setlectedMethodParams["chart_overlay"],
                        multi_monitoring_images_cartographic: setlectedMethodParams["multi_monitoring_images_cartographic"],
                        color_ramp: setlectedMethodParams["color_ramp"]
                    })).unwrap();
                }
                catch (error) {
                    console.error('Error creating AOI report:', error);
                }
            }),
        };
    };
    const confirmClose = () => {
        setIsConfirmCancelDialogOpen(false);
        props.onClose();
    };
    const cancelClose = () => {
        setIsConfirmCancelDialogOpen(false);
    };
    if (isLoading) {
        return React.createElement("div", null, "Loading job details...");
    }
    if (error) {
        return React.createElement("div", null, "Error loading job details");
    }
    const ValidationDialog = ({ isOpen, onClose, message }) => {
        return (React.createElement(Dialog, { title: "Report Requested", open: isOpen, onClose: onClose },
            React.createElement(StyledValidationDialogContent, { className: cx(GridContentStyles.max) },
                message,
                React.createElement(StyledButtonContainer, null,
                    React.createElement(PrimaryButton, { onClick: onClose, size: "small" }, "Close")))));
    };
    return (React.createElement(Dialog, { title: "Reports", onClose: () => setIsConfirmCancelDialogOpen(true), open: props.isOpen, FooterProps: getFooterProps() },
        React.createElement(SecondaryInfoContainer, { className: cx(GridContentStyles.max, GridContentStyles.dialogBodyContent) },
            React.createElement("div", { className: GridContentStyles.max },
                React.createElement(Select, { label: "Select a pregenerated report to download", value: selectedReport, onChange: (event, value) => handleSelectReportMethod(value), placeholder: "Select a report", disabled: Object.keys(ReportOptionMap).length === 0 }, Object.entries(ReportOptionMap).map(([value, path]) => (React.createElement(SelectOption, { key: value, value: path }, value)))))),
        React.createElement("div", { className: GridContentStyles.max },
            React.createElement(StyledLabelContainer, null,
                React.createElement(Label, { label: "Request an Ad-Hoc Report" }))),
        React.createElement(SecondaryInfoContainer, { className: cx(GridContentStyles.max, GridContentStyles.dialogBodyContent) },
            React.createElement("div", { className: GridContentStyles.max },
                React.createElement(Select, { label: "What type of report are you interested in?", onChange: (event, value) => handleSelectMethod(value), value: selectedMethodDisplayName, placeholder: "Select a report type", required: true }, Object.entries(MethodOptionMap).map(([value, displayName]) => {
                    return (React.createElement(SelectOption, { key: value, value: value }, displayName));
                }))),
            React.createElement("div", { className: GridContentStyles.max },
                React.createElement(StyledLabelContainer, null,
                    React.createElement(Label, { label: "Select time period for the report", required: true }))),
            React.createElement(DateRangePicker, { startDate: customStartDate, endDate: customEndDate, onChangeStartDate: (date) => handleSetCustomDate(date, 'start'), onChangeEndDate: (date) => handleSetCustomDate(date, 'end'), minDate: jobDetails ? DateTime.fromISO(jobDetails.start_date) : null, maxDate: jobDetails ? DateTime.fromISO(jobDetails.end_date) : null, StartDateProps: {
                    containerClassName: GridContentStyles.mediumSmall,
                    error: customStartDate !== null && !customStartDate.isValid,
                    errorMessage: 'Invalid start date',
                }, EndDateProps: {
                    containerClassName: GridContentStyles.mediumSmall,
                    error: customEndDate !== null && !customEndDate.isValid,
                    errorMessage: 'Invalid end date',
                } })),
        isConfirmCancelDialogOpen && (React.createElement(ConfirmCancelProcessDialog, { processName: "creating the AOI report", isOpen: isConfirmCancelDialogOpen, onClose: cancelClose, onCancelProcess: confirmClose })),
        React.createElement(ValidationDialog, { isOpen: isValidationDialogOpen, onClose: handleValidationDialogClose, message: validationMessage })));
};
const onDownloadReportClick = (selectedReportPath) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        if (!selectedReportPath) {
            return null;
        }
        const fileUrl = yield downloadzipFileFromS3(selectedReportPath);
        const fileName = extractFilenameFromPath(selectedReportPath);
        downloadFileToLocal(fileName, fileUrl);
    }
    catch (error) {
        console.error('Error downloading file:', error);
    }
});
