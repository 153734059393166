import { RemainingCharactersTextInput, TextInput, Slider } from '@orbica/component-sdk';
import GridContentStyles from '@orbica/component-sdk/build/css/gridContent.scss';
import React from 'react';
import { TitleAndDescription } from './TitleAndDescription';
export const DescriptionStage = ({ name, onSetName, description, onSetDescription, cloud_coverage, onSetCloudCoverage }) => {
    const handleInputChange = (event, attribute) => {
        if (attribute === 'name') {
            onSetName(event.target.value);
        }
        else {
            onSetDescription(event.target.value);
        }
    };
    const handleSliderChange = (event, value) => {
        onSetCloudCoverage(value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleAndDescription, { title: "Add a name and a brief description for your job." }),
        React.createElement(RemainingCharactersTextInput, { required: true, label: "Name", containerClassName: GridContentStyles.max, value: name, onChange: (e) => handleInputChange(e, 'name'), maxCharacters: 63 }),
        React.createElement(TextInput, { label: "Description", containerClassName: GridContentStyles.max, value: description, onChange: (e) => handleInputChange(e, 'description'), multiline: true, rows: 3 }),
        React.createElement(Slider, { min: 0, max: 100, value: cloud_coverage, onChange: (e, value) => handleSliderChange(e, value), containerClassName: GridContentStyles.mediumSmall, label: "Cloud Cover" })));
};
