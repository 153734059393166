import agriculture from '@Icons/jobTopics/agriculture.svg';
import custom from '@Icons/jobTopics/custom.svg';
import forestry from '@Icons/jobTopics/forestry.svg';
import snow from '@Icons/jobTopics/snow.svg';
import urban from '@Icons/jobTopics/urban.svg';
import water from '@Icons/jobTopics/water.svg';
import parks from '@Icons/jobTopics/parks.svg';
import burn from '@Icons/jobTopics/burn.svg';
import lake_river from '@Icons/jobTopics/lake_river.svg';
import { DateTime } from 'luxon';
export function getThemeIcon(themeName) {
    if (!themeName)
        return custom;
    switch (themeName.toLowerCase()) {
        case 'agriculture':
            return agriculture;
        case 'forestry':
            return forestry;
        case 'snow':
            return snow;
        case 'urban':
            return urban;
        case 'water':
            return water;
        case 'parks':
            return parks;
        case 'burn scar':
            return burn;
        case 'algae bloom':
            return lake_river;
        case 'custom':
            return custom;
        default:
            console.error('unhandled theme passed to getThemeIcon');
            return custom;
    }
}
export const Sentinel2ComparisonLayers = [
    {
        type: 'trueColor',
        label: 'Natural Colour (True Colour)',
        urlTemplate: '&bidx=1&assets=visual&asset_bidx=visual%7C1%2C2%2C3&resampling=nearest&reproject=nearest&return_mask=true',
        description: 'Natural colour refers to an accurate representation of colours as seen by the human eye, without any artificial alterations or enhancements. It captures the natural hues and tones of objects or images as they appear in reality.',
    },
    {
        type: 'falseColor',
        label: 'Enhanced Colour (False Colour)',
        urlTemplate: '&bidx=1&assets=data&expression=nir%2A%2A0.5%3Bred%2A%2A0.5%3Bgreen%2A%2A0.5&asset_bidx=data%7C1%2C2%2C3&asset_bidx=cog%7C1&asset_as_band=True&resampling=nearest&reproject=nearest&return_mask=true',
        description: 'Enhanced colour is a visual representation technique where colours are altered to reveal hidden or specific features in an image. It allows for easier interpretation or analysis by enhancing contrast and highlighting details that may not be visible in standard colour schemes.',
    },
    {
        type: 'scl',
        label: 'Sentinel Classification Layer',
        urlTemplate: '&bidx=1&assets=scl&asset_bidx=scl%7C1&unscale=false&resampling=nearest&reproject=nearest&colormap=%7B%221%22%3A%20%22%23FF0000%22%2C%20%222%22%3A%20%22%232E2E2E%22%2C%20%223%22%3A%20%22%23643200%22%2C%20%224%22%3A%20%22%23008000%22%2C%20%225%22%3A%20%22%23FFE65A%22%2C%20%226%22%3A%20%22%230000FF%22%2C%20%227%22%3A%20%22%23818181%22%2C%20%228%22%3A%20%22%23C1C1C1%22%2C%20%229%22%3A%20%22%23FFFFFF%22%2C%20%2210%22%3A%20%22%2364C8FF%22%2C%20%2211%22%3A%20%22%23FF96FF%22%7D&return_mask=true',
        description: 'The Sentinel Classification Layer identifies and categorises scenes in images into basic classes like forests, cities, beaches, and mountains. It turns visual data into clear scene labels for better understanding.',
    },
    {
        type: 'cloud',
        label: 'Cloud Probability Layer',
        urlTemplate: '&bidx=1&assets=cloud&asset_bidx=1%7C1%2C2%2C3&resampling=nearest&reproject=nearest&return_mask=true',
        description: 'The Cloud Probability Layer identifies and categorises scenes in images into probability of cloud.',
    },
];
export const SENTINEL_2_MIN_DATE = DateTime.fromISO('2018-01-01T00:00:00');
